@import url(https://fonts.googleapis.com/css2?family=Rubik+Bubbles&display=swap);
html,body {
  font-family:  'Rubik Bubbles', cursive;
  font-weight: 400;
  font-size: medium;
  width: 100%;
  height: 100%;
}
nav{
  background-color: darkslategrey;
  width:100%;
}
.text-primary {
  color: #083969 !important;
}

.text-secondary {
  color: #335275 !important;
}

.text-muted {
  color: #101212 !important;
}

.btn-primary {
  background-color: #3498db;
  border-color: #3498db;
}
.emoji-story {
  font-size: 35px;
 background-color: coral;
 height:50px;
}
.game-container {

  color: rgb(4, 3, 3);
  padding: 2rem;

}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  border-color: #505253;
}

.btn-light {
  background-color: #ecf0f1;
  border-color: #ecf0f1;
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}

.text-coral {
  color: coral;
}
.message-box {
  
  overflow: hidden;
  transition: all 0.3s;
}
.splash-message-box{
  border: 5px solid black;
}
.quiz-container {
  background-color: #212529;
  color: white;
  padding: 2rem;
  min-height: 100%;
}

.quiz-container h1 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.quiz-container .form-group {
  margin-bottom: 1.5rem;
}

.quiz-container .form-control {
  background-color: #343a40;
  border-color: #454d55;
  color: white;
}

.quiz-container .btn-outline-light {
  color: white;
  border-color: white;
  margin-bottom: 0.5rem;
}

.quiz-container .btn-outline-light.active {
  background-color: white;
  color: #212529;
}
#root,.app, .input-group, .splash-page{
  background-image: url(../../static/media/cat.c6921df8.jpg);
  background-size: cover;
  background-repeat: no-repeat;
width: 100vw;
min-height: 100%;
}


.messages-container {
  height: 500px;
}

.large {
  font-family: "Audiowide", cursive;
	font-size: 3rem;
}
.container-fluid {
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(to right, #1a1a1a, #0d47a1, #1a1a1a); */
}
.small {
  font-family: "Audiowide", cursive;
	font-size: 1.5rem;
}

.rise {
	text-shadow: -0.01em -0.01em 0.01em #014e75;
	-webkit-animation: rise 2s ease-in-out 0.5s forwards;
	        animation: rise 2s ease-in-out 0.5s forwards;
}

@-webkit-keyframes rise {
	to {
		text-shadow: 0em 0.01em rgb(7, 164, 145), 0em 0.02em rgb(178, 109, 49), 0em 0.02em 0.03em rgb(243, 126, 1),
			-0.01em 0.01em #5de399, -0.02em 0.02em #28d158, -0.03em 0.03em #333,
			-0.04em 0.04em #54d57b, -0.01em -0.01em 0.03em #95caed, -0.02em -0.02em 0.03em #179080,
			-0.03em -0.03em 0.03em #49236f;
		-webkit-transform: translateY(-0.025em) translateX(0.04em);
		        transform: translateY(-0.025em) translateX(0.04em);
	}
}

@keyframes rise {
	to {
		text-shadow: 0em 0.01em rgb(7, 164, 145), 0em 0.02em rgb(178, 109, 49), 0em 0.02em 0.03em rgb(243, 126, 1),
			-0.01em 0.01em #5de399, -0.02em 0.02em #28d158, -0.03em 0.03em #333,
			-0.04em 0.04em #54d57b, -0.01em -0.01em 0.03em #95caed, -0.02em -0.02em 0.03em #179080,
			-0.03em -0.03em 0.03em #49236f;
		-webkit-transform: translateY(-0.025em) translateX(0.04em);
		        transform: translateY(-0.025em) translateX(0.04em);
	}
}

.outline {
	-webkit-background-clip: text;
	        background-clip: text;
	color: #c2ec57;
	-webkit-animation: outline 1s ease-in-out 1.5s forwards;
	        animation: outline 1s ease-in-out 1.5s forwards;
}

@-webkit-keyframes outline {
	from {
		text-shadow: 0em -7em 10em #e3ee0b;
	}
	to {
		text-shadow: 0 0.02em #fff, 0.02em 0 #fff, -0.02em 0 #fff, 0 -0.02em #fff;
	}
}

@keyframes outline {
	from {
		text-shadow: 0em -7em 10em #e3ee0b;
	}
	to {
		text-shadow: 0 0.02em #fff, 0.02em 0 #fff, -0.02em 0 #fff, 0 -0.02em #fff;
	}
}

.messages-box {
  height: 100%;
  background-color: white;
  overflow-y: auto;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.splash-message-box {
  border: 4px solid #007bff;
  border-radius: 15px;
  max-height: 100%;
  overflow-y: auto;
  padding: 10px;
}

.splash-message-box p {
  font-size: 1.2rem;
  margin: 0;
  padding: 10px;

}

.splash-message-box .text-right {
  background-color: #007bff;
  color: #080707;
 margin-right: 50%;

}

.splash-message-box .text-left {
  background-color: #e9ecef;
  color: #212529;
 
  margin-left: 50%;
}

.emoji-story {
  height: 1em;
  line-height: 1em;
  background-color: #179080;
  font-size: 4em;
  text-align: center;
  border-radius: 5%;
  padding: 2%;
}

.messages-input-form {
  display: flex;
  align-items: center;
}

.text-bubble {
  padding: 0.5rem;
  border-radius: 5px;
}

.user-bubble {
  background-color: #4b8dd9;
  color: rgb(7, 1, 1);
}

.bot-bubble {
  background-color: #f1f1f1;
  color: black;
}
/* Add this to your CSS file */
@-webkit-keyframes loading-dots {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
@keyframes loading-dots {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
.input-text, .output-text {
  width: 100%;
  color: black;
}
.output-text{
  margin-top: 15%;
}
.loading-dot {
  -webkit-animation: loading-dots 1.4s infinite;
          animation: loading-dots 1.4s infinite;
  opacity: 0;
  font-size: 1.5em;
  margin-left: 2px;
  margin-right: 2px;
}

.loading-dot:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.align-form-items {
  display: flex;
  align-items: center;
}
.link-bg {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

